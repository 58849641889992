
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiImportOrderList } from '@/api/order'
import { apiShopLists } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import LsPagination from '@/components/ls-pagination.vue'
import elImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        elImageViewer,
        DatePicker,
        ExportData
    }
})
export default class plantOrder extends Vue {
    apiImportOrderList = apiImportOrderList

    pager: any = new RequestPaging()
    // 保存请求数据
    searchObj: any = {
        sid: '',
        start_time: '',
        end_time: '',
        time_type: ''
    }
    cancelParams: any = {
        id: '',
        money: '',
        reason: ''
    }
    shopList: any = []

    timestampToYYMMDDHHMMSS(timestamp: number) {
        let date = new Date(timestamp * 1000) // 将时间戳转换为毫秒
        let year = date.getFullYear()
        let month = ('0' + (date.getMonth() + 1)).slice(-2)
        let day = ('0' + date.getDate()).slice(-2)
        let hours = ('0' + date.getHours()).slice(-2)
        let minutes = ('0' + date.getMinutes()).slice(-2)
        let seconds = ('0' + date.getSeconds()).slice(-2)

        let formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
        return formattedDate
    }
    computedLogistics(obj: any) {
        try {
            obj = JSON.parse(obj)
            const keys = Object.keys(obj)
            // 遍历所有属性，并构建一个包含属性名和属性值的字符串数组
            const propertiesArray = keys.map(key => {
                return `${key}: ${obj[key].split('-')[0]}`
            })
            // 使用join方法将数组元素用逗号和空格连接成一个字符串
            return propertiesArray.join(', ')
        } catch (error) {
            return ''
        }
    }
    getCurrencyUnit(currency: string) {
        if (currency === 'USD') {
            return '$'
        } else {
            return '￥'
        }
    }
    canTransfer(value: any) {
        const res = value.find(
            (item: any) => item.produce_sid != null && item.produce_sid != item.sid && item.produce_status == 0
        )
        return res === undefined ? false : true
    }
    getTime(timestamp: string) {
        let time = new Date(Number(timestamp) * 1000)
        let year = time.getFullYear()
        const month = (time.getMonth() + 1).toString().padStart(2, '0')
        const date = time.getDate().toString().padStart(2, '0')
        const hours = time.getHours().toString().padStart(2, '0')
        const minute = time.getMinutes().toString().padStart(2, '0')
        const second = time.getSeconds().toString().padStart(2, '0')

        return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second
    }

    showTimeFormatt(diffTime: any, index: any) {
        if (diffTime === null || diffTime === 0 || diffTime === '' || diffTime === undefined) return false
        let msg = ''
        if (diffTime <= 0) {
            // 已过期
            diffTime = Math.abs(diffTime)
            msg = '已超时:'
        } else {
            msg = '距离到期:'
        }
        const totalSeconds = Math.floor(diffTime / 1000)
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60
        return `${msg}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`
    }

    getOrderList(page?: number) {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiImportOrderList,
                params: {
                    ...this.searchObj
                }
            })
            .then((res: any) => {})
    }

    getShopList() {
        apiShopLists({ page_no: 1, page_size: 999 }).then((res: any) => {
            this.shopList = res.lists
        })
    }

    created() {
        this.getShopList()
        this.getOrderList()
    }
}
